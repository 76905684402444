.sidebar {
  background: #ffffff;
  box-shadow: 0px 15px 45px rgba(220, 225, 239, 0.45);
  border-radius: 12px;
  width: 80px;
  padding: 30px 20px;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  a {
    margin-bottom: 25px;
    &:last-child {
      margin-bottom: 0;
    }
    svg {
      fill: #bac3d7;
    }

    &.active,
    &:hover {
      svg {
        fill: #3262ff;
      }
    }
  }
}
