@import './variables';
// @import '~normalize.css';
@import '~react-toastify/dist/ReactToastify.css';
@import '../fonts/gilroy/stylesheet.css';

* {
  border: 0;
  padding: 0;
  margin: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: 'Gilroy', sans-serif;
}

button {
  border: none;
  outline: none;
}

html {
  font-family: 'Gilroy', sans-serif;
  body {
    background-color: $background;

    #root {
      max-width: 1920px;
      min-width: 1920px;
      margin: 0 auto;
      padding: 35px 70px;
    }
  }
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: #f6f7fb;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: #e6e8f0;
}
