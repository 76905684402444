.pass {
  width: 390px;
  height: 210px;
  padding: 30px;
  background: #1b1b24;
  border-radius: 18px;
  margin-bottom: 25px;
  .head {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 15px;
    color: white;
    .badge {
      background: radial-gradient(
        68% 68% at 50.67% 100%,
        rgba(#a03edd, 0.45) 0%,
        rgba(152, 61, 215, 0.2) 100%
      );
      // opacity: 0.45;
      border-radius: 14px;
      width: 70px;
      margin-right: 15px;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .description {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #a4a4cf;
  }
}
