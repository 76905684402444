@use 'src/assets/css/variables' as *;

.banners {
  display: flex;
  justify-content: space-between;
}

.games {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  a {
    display: flex;
    width: 180px;
    height: 180px;
    background: $bg-green;
    border-radius: 16px;
    margin-bottom: 25px;
    flex-direction: column;
    svg {
      fill: $bg-white;
      margin-bottom: 15px;
      height: 55px;
      width: 55px;
    }
    color: $bg-white;
    text-decoration: none;
    justify-content: center;
    font-weight: 600;
    font-size: 18px;
    align-items: center;
    text-align: center;
    &:nth-child(2) {
      background-color: $bg-orange;
    }
    &:nth-child(3) {
      background-color: $bg-purple;
    }
    &:nth-child(4) {
      background-color: $bg-blue;
    }
    &:nth-child(5) {
      background-color: $bg-lightblue;
    }
    &:nth-child(6) {
      background-color: $bg-yellow;
    }

    &:hover {
      opacity: 0.8;
    }
  }
}

.benefits {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .benefit {
    width: calc(100% / 3 - 15px);
    background-color: $bg-white;
    border-radius: 14px;
    margin-bottom: 25px;

    display: flex;
    justify-content: space-between;
    padding: 25px 20px;

    .badge {
      width: 60px;
      height: 60px;
      background: $bg-green;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .info {
      width: calc(100% - 60px - 15px);
      .title {
        font-weight: 700;
        font-size: 18px;
        color: #1a1a1a;
        margin-bottom: 5px;
      }
      .description {
        color: #aebad4;
        font-weight: 600;
        font-size: 14px;
      }
    }

    &:nth-child(2) .badge {
      background-color: $bg-orange;
    }
    &:nth-child(3) .badge {
      background-color: $bg-purple;
    }
    &:nth-child(4) .badge {
      background-color: $bg-blue;
    }
    &:nth-child(5) .badge {
      background-color: $bg-lightblue;
    }
    &:nth-child(6) .badge {
      background-color: $bg-yellow;
    }
  }
}
