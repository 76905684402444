@use 'src/assets/css/variables' as *;

$smile: 20px;
$send: 43px;

.chat {
  background: $bg-white;
  border-radius: 16px;
  padding: 25px;
  width: 430px;
  height: 100%;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eff4ff;
    padding-bottom: 20px;

    .left {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 16px;
      .badge {
        margin-right: 10px;
        width: 45px;
        height: 45px;
        display: flex;
        border-radius: 45px;
        align-items: center;
        justify-content: center;
        background-color: $bg-primary;
      }
    }

    .online {
      background: rgba(239, 244, 255, 0.35);
      border-radius: 100px;
      display: flex;
      align-items: center;
      width: 120px;
      height: 45px;
      padding: 0 20px;
      font-weight: 700;
      font-size: 15px;
      color: #1a1a1a;
      svg {
        margin-right: 10px;
      }
    }
  }

  .messages {
    padding: 20px 15px 0 0;
    height: 600px;
    overflow-y: auto;
  }

  .input {
    display: flex;
    align-items: center;
    height: 60px;
    justify-content: space-between;
    border: 1px solid #eff4ff;
    border-radius: 60px;
    margin-top: 15px;
    padding: 10px 10px 10px 20px;
    input {
      width: calc(100% - $smile - $send - 20px);
      font-weight: 600;
      font-size: 15px;
      &::placeholder {
        color: #b4c2df;
      }
    }
    button {
      &.send {
        width: $send;
        height: $send;
        border-radius: $send;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $bg-primary;
        svg {
          fill: white;
        }
      }
      &.smile {
        background-color: transparent;
      }
      &:hover {
        opacity: 0.7;
        cursor: pointer;
      }
    }
  }
}
