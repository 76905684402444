@use 'src/assets/css/variables' as *;

$logotype-description: 290px;
$logotype: $logotype-description + 150px + 25px;
$button: 42px;
$socials: $button * 3 + 20px;

.footer {
  width: 100%;
  border-top: 1px solid #e5e8ef;
  padding-top: 25px;
  display: flex;
  margin-top: 45px;
  align-items: center;
  justify-content: space-between;
  .logotype {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: $logotype;
    .description {
      width: $logotype-description;
      font-weight: 600;
      font-size: 14px;
      color: #aebad5;
    }
  }

  .socials {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: $socials;
    button {
      width: $button;
      height: $button;
      border-radius: $button;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(#bac3d7, 0.3);

      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }

      svg {
        fill: #bac3d7;
        height: 14px;
      }
    }
  }

  .nav {
    display: flex;
    align-items: center;
    width: calc(100% - $socials - $logotype - 55px);
    a {
      text-decoration: none;
      margin-right: 45px;
      font-weight: 700;
      font-size: 16px;
      color: $text-light;
      display: flex;
      align-items: center;
      height: 35px;
      position: relative;

      svg {
        width: 24px;
        height: 24px;
        fill: $text-light;
        margin-right: 10px;
      }

      &:hover {
        color: $text-dark;
      }
    }
  }
}
