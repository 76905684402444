$profile: 40px;
$profile-right: 20px;

.message {
  margin-bottom: 15px;
  display: flex;
  a {
    width: $profile;
    margin-right: $profile-right;
    text-decoration: none;
    font-weight: 700;
    font-size: 12px;
    color: #c7d0ee;
    text-align: center;
    img {
      width: $profile;
      height: $profile;
      object-fit: cover;
      margin-bottom: 5px;
    }

    &:hover {
      opacity: 0.7;
    }
  }
  .info {
    width: calc(100% - $profile - $profile-right);
    .name {
      margin-bottom: 10px;
      font-weight: 700;
      font-size: 16px;
      color: #282e43;
    }
    .text {
      background: rgba(239, 244, 255, 0.45);
      border-radius: 12px;
      color: #98a1c1;
      word-wrap: break-word;
      padding: 20px;
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
    }
  }
}
