.gift {
  width: calc(100% - 390px - 25px);
  height: 210px;
  padding: 30px 50px;
  background: #1b1b24;
  border-radius: 18px;
  margin-bottom: 25px;
  overflow: hidden;
  position: relative;

  &::before {
    background: radial-gradient(
      50% 50% at 50% 50%,
      #5a8cff 0%,
      rgba(90, 140, 255, 0) 100%
    );
    filter: blur(35px);
    border-radius: 333px;
    content: '';
    width: 224px;
    height: 236px;
    position: absolute;
    left: -123px;
    top: -115px;
  }

  &::after {
    background: radial-gradient(
      50% 50% at 50% 50%,
      #5a8cff 0%,
      rgba(90, 140, 255, 0) 100%
    );
    filter: blur(35px);
    border-radius: 333px;
    content: '';
    width: 224px;
    height: 236px;
    position: absolute;
    left: 613px;
    top: 112px;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;

  .info {
    width: calc(100% - 140px);
    .title {
      font-weight: 700;
      font-size: 24px;
      color: white;
      margin-bottom: 15px;
    }
    .description {
      font-weight: 600;
      font-size: 16px;
      color: #a4a4cf;
    }
  }
}
