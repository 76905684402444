@use 'src/assets/css/variables' as *;

$logo-right: 45px;
$logo-width: 150px;
$rightbar-width: 430px;
$bottom: 45px;

.header {
  display: flex;
  //   justify-content: space-between;
  align-items: center;
  margin-bottom: $bottom;
  a.logotype {
    &:hover {
      opacity: 0.7;
    }
    height: 40px;
    // display: block;
    margin-right: $logo-right;
  }

  .nav {
    display: flex;
    align-items: center;
    width: calc(100% - $rightbar-width - $logo-right - $logo-width);
    a {
      text-decoration: none;
      margin-right: 60px;
      font-weight: 700;
      font-size: 16px;
      color: $text-light;
      display: flex;
      align-items: center;
      height: 35px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 0;
        right: 0;
        background-color: $bg-secondary;
        height: 3px;
        border-radius: 3px;
        width: 0;
        margin: 0 auto;
        transition: 250ms ease;
      }

      svg {
        width: 24px;
        height: 24px;
        fill: $text-light;
        margin-right: 10px;
      }

      &.active svg {
        fill: $bg-blue;
      }

      &:hover,
      &.active {
        color: $text-dark;
        &::after {
          width: 100%;
        }
      }
    }
  }

  .rightbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: $rightbar-width;
    button {
      width: 52px;
      height: 52px;
      border-radius: 52px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }

      &.auth {
        width: 150px;
        background-color: #4d7eff;
        font-weight: 700;
        font-size: 16px;
        color: white;
        padding-right: 15px;
        span {
          width: 30px;
          position: absolute;
          right: 10px;
          height: 30px;
          border-radius: 30px;
          background-color: rgba(white, 0.15);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      &.notify {
        background-color: #eceff1;
        span {
          position: absolute;
          right: 0;
          top: 0;
          width: 15px;
          height: 15px;
          background-color: $bg-green;
          border: 4px solid $background;
          border-radius: 10px;
        }
      }

      &.vk {
        background-color: #0077ff;
      }

      &.facebook {
        background-color: #116bdf;
      }

      &.telegram {
        background-color: #26b5ff;
      }
    }

    .socials {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: calc(52px * 3 + 20px);
      button svg {
        fill: white;
      }
    }
  }
}
