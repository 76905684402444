$background: #f6f8fa;
$bg-white: #ffffff;

$bg-green: #42ca50;
$bg-orange: #ff6633;
$bg-purple: #8933ff;
$bg-blue: #3262ff;
$bg-lightblue: #33beff;
$bg-yellow: #f8c924;

$bg-primary: #4d7eff;
$bg-light: rgba(#eff4ff, 0.35);
$bg-secondary: #d3d9e5;

$text-dark: #1a1a1a;
$text-light: #aebad5;
