.main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .app {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: calc(100% - 430px - 25px);
    align-self: flex-start;

    .content {
      width: calc(100% - 80px - 25px);
    }
  }
}
